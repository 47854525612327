import React, {useState} from 'react';
import UpdateEmailPrefs from './UpdateEmailPrefs';
import {dataLayerPush} from 'utils/dataLayer';
import PropTypes from 'prop-types';
import PreferencesContent from "./PreferencesContent";

EmailPreferences.propTypes = {
  preferences: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  showGroupHeader: PropTypes.bool,
  title: PropTypes.string
}

function EmailPreferences({preferences, loading, error, showGroupHeader = true, title}) {
  const [editOpen, setEditOpen] = useState(false);

  const handleOpen = () => {
    dataLayerPush(`Click to open 'Update Email Preferences'`);
    setEditOpen(true);
  };

  const handleClose = () => {
    setEditOpen(false);
  };


  return (
    <>
      {preferences &&
        <PreferencesContent
          loading={loading}
          error={error}
          preferenceGroups={preferences}
          showGroupHeader={showGroupHeader}
          open={editOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          modalContent={<UpdateEmailPrefs
            data={preferences}
            onClose={handleClose}
            title={title}
          />}
        />
      }
    </>
  );
}

export default React.memo(EmailPreferences);

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import BobbyJonesCondensed from 'assets/fonts/BobbyJones-Condensed.woff2';
import BobbyJonesRegular from 'assets/fonts/BobbyJones-Regular.woff2';

const bobbyJonesCondensed = {
  fontFamily: 'BobbyJones-Condensed',
  fontStretch: 'condensed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('BobbyJones'),
    local(BobbyJones-Condensed),
    url(${BobbyJonesCondensed}) format('woff2')
  `,
};

const bobbyJonesRegular = {
  fontFamily: 'BobbyJones-Regular',
  fontStretch: 'condensed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('BobbyJones'),
    local(BobbyJones-Regular),
    url(${BobbyJonesRegular}) format('woff2')
  `,
};

const bobbyJonesRegularFallback = {
  fontFamily: 'BobbyJones-Regular-Fallback',
  sizeAdjust: '98%',
  ascentOverride: '110%',
  src: `local('Impact')`,
}

const heeboFallback = {
  fontFamily: 'Heebo-fallback',
  sizeAdjust: '96.9%',
  ascentOverride: '100%',
  src: `local('Trebuchet MS')`,
};

const heeboFallbackBold = {
  fontFamily: 'Heebo-fallback-bold',
  sizeAdjust: '98.5%',
  ascentOverride: '88%',
  src: `local('Trebuchet MS')`,
};

const robotoFallback = {
  fontFamily: 'Roboto Condensed-fallback',
  sizeAdjust: '98%',
  ascentOverride: '102.5%',
  src: `local('Impact')`,
};

const robotoFallbackItalic = {
  fontFamily: 'Roboto Condensed-fallback-italic',
  sizeAdjust: '97.5%',
  ascentOverride: '102.5%',
  src: `local('Impact')`,
};

const fontHeebo = ['Heebo', 'Heebo-fallback', 'sans-serif'].join(',');

const fontRobotoCondensed = [
  'Roboto Condensed',
  'Roboto Condensed-fallback',
  '"Franklin Gothic Bold"',
  'sans-serif',
].join(',');

export const fonts = {
  heebo: fontHeebo,
  robotoCondensed: fontRobotoCondensed,
};

export const colors = {
  white: '#ffffff',
  whiteTransparent: '#ffffffb3',
  whiteVeryTransparent: '#ffffff80',
  black: '#000000',
  lightRed: '#ef3340',
  ttMuted: '#b5121b',
  ttRed: '#e31b23',
  darkRed: '#842521',
  green: '#679146',
  lightGreen: '#A0CF67',
  gray: '#F8F8F8',
  inputGray: '#707070',
  mutedLightGray: '#807f83',
  borderGray: '#cccccc',
  lightGray: '#ececec',
  videoBackground: '#181818',
  activeBackgroundGray: '#33333376',
  lightBorderGray: '#e3e3e3',
  mediumGray: '#c9c9c9',
  mutedGray: '#333333',
  mutedDarkGray: '#222222',
  backgroundGray: '#FAFAFA',
  marigold: '#f9a73f',
  upcomingPlaceholderText: '#00000080',
  upcomingPlaceholderTextDark: '#ffffff40',
  dark: '#0A080A',
  darkCharcoal: '#1d191b',
  cardBoxShadow: '#00000029',
  toolHoverBackgroundGray: '#0000000D',
  iconHoverBackgroundGray: '#efefef',
  accordionTitleBackgroundGray: '#f4f4f4',
  lighterGray: '#f7f7f7',
  limeGreen: '#c7ff3e',
  twitterBlue: '#1d9cf0',
  twitterGray: '#838A8A',
  twitterHoverGray: '#f7f9f9',
};

// Create a theme instance.
let theme = createMuiTheme({
  colors,
  fonts,
  breakpoints: {
    custom: {
      mobileSm: '320px',
      mobileMd: '400px',
      mobileLg: '480px',
      deskLg: '1440px'
    },
  },
  palette: {
    primary: {
      main: colors.white,
    },
    secondary: {
      main: colors.ttMuted,
    },
    error: {
      main: colors.ttRed,
    },
    background: {
      default: colors.backgroundGray,
    },
    text: {
      // The most important text.
      primary: colors.black,
      // Secondary text.
      secondary: 'rgba(0, 0, 0, 0.54)',
      // Disabled text have even lower visual prominence.
      disabled: 'rgba(0, 0, 0, 0.38)',
      // Text hints.
      hint: colors.mutedGray,
    },
  },
  typography: {
    fontFamily: fonts.heebo,
    fontFamilySecondary: fonts.robotoCondensed,
    h1: {
      fontFamily: fonts.robotoCondensed,
      fontSize: '2.5rem',
      fontWeight: '600',
      fontStretch: 'condensed',
      fontStyle: 'normal',
      lineHeight: '1.33',
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily: fonts.robotoCondensed,
      fontSize: '2.5rem',
      fontWeight: '600',
      fontStretch: 'condensed',
      fontStyle: 'normal',
      lineHeight: '1.33',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: fonts.robotoCondensed,
      fontSize: '1.875rem',
      fontWeight: '600',
    },
    h4: {
      fontFamily: fonts.robotoCondensed,
      fontSize: '1rem',
      fontWeight: '600',
      fontStretch: 'condensed',
      fontStyle: 'normal',
      lineHeight: '1.31',
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily: fonts.robotoCondensed,
      fontWeight: '400',
      fontSize: '.8125rem',
    },
    h6: {
      fontFamily: fonts.robotoCondensed,
      fontWeight: '400',
      fontSize: '.625rem',
    },
    p: {
      fontWeight: '400',
    },
  },
  spacing: (factor) => `${0.5 * factor}rem`,
});

// Implement theme overrides separately so that we can use some of the theme helper methods and variables.
theme = createMuiTheme(theme, {
  overrides: {
    MuiContainer: {
      maxWidthSm: {
        maxWidth: '1080px', // defined from UI direction
      },
      maxWidthMd: {
        maxWidth: '1080px', // defined from UI direction
      },
      maxWidthLg: {
        maxWidth: '1280px', // defined from UI direction - keeping custom for now because I'm expecting this to change.
      },
      maxWidthXl: {
        maxWidth: '1380px', // defined from UI direction
      },
      maxWidthXxl: {
        maxWidth: '1920px',
      },
    },
    MuiTabs: {
      indicator: {
        height: '1px',
        color: colors.lightRed,
      },
    },
    MuiTab: {
      textColorInherit: {
        color: 'black',
        '&$selected': {
          color: colors.black,
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      /* Styles applied to the root element. */
      root: {
        borderRadius: '.5625rem',
        fontFamily: fonts.robotoCondensed,
        fontWeight: 'bold',
        fontSize: '1rem',
        letterSpacing: '0.32px',
        padding: '0.8125rem 2.8125rem',
        lineHeight: '1.31',
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '& svg': {
          transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&$disabled': {
          '& svg': {
            fill: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        padding: '0.8125rem 2.8125rem',
        border: `solid 1px ${colors.black}`,
        color: colors.black,
        '& svg': {
          fill: colors.black,
        },
        '&:hover': {
          border: `1px solid ${colors.black}`,
          color: colors.white,
          backgroundColor: colors.black,
          '& svg': {
            fill: colors.white,
          },
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
      outlinedPrimary: {
        border: `solid 1px ${colors.black}`,
        color: colors.black,
        '& svg': {
          fill: colors.black,
        },
        '&:hover': {
          border: `1px solid ${colors.ttRed}`,
          color: colors.white,
          backgroundColor: colors.ttRed,
          '& svg': {
            fill: colors.white,
          },
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
      outlinedSecondary: {
        border: `solid 1px ${colors.white}`,
        color: colors.white,
        '& svg': {
          fill: colors.white,
        },
        '&:hover': {
          border: `1px solid ${colors.white}`,
          color: colors.black,
          backgroundColor: colors.white,
          '& svg': {
            fill: colors.white,
          },
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      /* Styles applied to the root element if `variant="contained"`. */
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
      containedPrimary: {
        backgroundColor: colors.ttRed,
        color: colors.white,
        '& svg': {
          fill: colors.white,
        },
        '&:hover': {
          backgroundColor: colors.ttMuted,
          boxShadow: 'none',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: colors.ttRed,
          },
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
      containedSecondary: {},
      /* Styles applied to the root element if `variant="text"`. */
      text: {
        padding: '6px 8px',
        color: colors.black,
        '& svg': {
          fill: colors.black,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: colors.ttRed,
          '& svg': {
            fill: colors.ttRed,
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
      textPrimary: {
        color: colors.ttRed,
        '& svg': {
          fill: colors.ttRed,
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: colors.ttMuted,
          '& svg': {
            fill: colors.ttMuted,
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
      textSecondary: {
        color: colors.white,
        '& svg': {
          fill: colors.white,
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      /* Styles applied to the root element if `size="small"` and `variant="outlined"`. */
      outlinedSizeSmall: {
        padding: '3px 9px',
      },
      /* Styles applied to the root element if `size="large"` and `variant="outlined"`. */
      outlinedSizeLarge: {
        padding: '26px 90px',
        fontSize: '1.25rem',
        lineHeight: '1.31',
      },
      /* Styles applied to the root element if `size="small"` and `variant="contained"`. */
      containedSizeSmall: {
        padding: '4px 10px',
      },
      /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
      containedSizeLarge: {
        padding: '26px 90px',
        fontSize: '1.25rem',
      },
      /* Styles applied to the startIcon element if supplied. */
      startIcon: {
        display: 'inherit',
        marginRight: '1.125rem',
        marginLeft: '-0.5625rem',
        '&$iconSizeSmall': {
          marginLeft: '-0.28125rem',
        },
      },
    },
    MuiBottomNavigation: {
      /* Styles applied to the root element. */
      root: {
        display: 'flex',
        justifyContent: 'center',
        height: 56,
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.colors.borderGray}`,
      },
    },
    MuiBottomNavigationAction: {
      /* Styles applied to the root element. */
      root: {
        transition: theme.transitions.create(
          ['color', 'background-color', 'padding-top'],
          {
            duration: theme.transitions.duration.short,
          }
        ),
        padding: '6px 12px 8px',
        minWidth: 60,
        maxWidth: 168,
        color: theme.palette.text.primary,
        flex: '1',
        '&$iconOnly': {
          paddingTop: 16,
        },
        '&$selected': {
          paddingTop: 6,
          color: theme.palette.primary.main,
          backgroundColor: theme.colors.ttRed,
        },
      },
      /* Pseudo-class applied to the root element if selected. */
      selected: {},
      /* Pseudo-class applied to the root element if `showLabel={false}` and not selected. */
      iconOnly: {},
      /* Styles applied to the span element that wraps the icon and label. */
      wrapper: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
      },
      /* Styles applied to the label's span element. */
      label: {
        fontFamily: theme.typography.fontFamilySecondary,
        fontSize: theme.typography.pxToRem(8),
        textTransform: 'uppercase',
        paddingTop: '.25rem',
        opacity: 1,
        transition: 'font-size 0.2s, opacity 0.2s',
        transitionDelay: '0.1s',
        '&$iconOnly': {
          opacity: 0,
          transitionDelay: '0s',
        },
        '&$selected': {
          fontSize: theme.typography.pxToRem(8),
        },
      },
    },
    // Dialog overrides
    MuiDialogTitle: {
      root: {
        padding: '1.25rem 1.25rem 0',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0.5rem 1.25rem',
      },
    },
    // Global overrides
    MuiCssBaseline: {
      '@global': {
        // Global CSS styles should be applied here. See: https://material-ui.com/customization/globals/#global-css
        '@font-face': [
          bobbyJonesCondensed,
          bobbyJonesRegularFallback,
          bobbyJonesRegular,
          heeboFallback,
          heeboFallbackBold,
          robotoFallback,
          robotoFallbackItalic,
        ],
        html: {
          WebkitFontSmoothing: 'auto',
        },

        li: {
          fontSize: '1rem',
        },

        p: {
          fontFamily: fonts.heebo,
          fontSize: '1rem',
          fontWeight: '300',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '1.5',
          letterSpacing: 'normal',
        },

        /*
          This will hide the focus indicator if the element receives focus via the mouse,
          but it will still show up on keyboard focus.
        */
        '.MuiLink-root:focus:not(.Mui-focusVisible)': {
          outline: 'none',
        },
      },
    },
  },
});

// Automate setup of responsive font sizes.
// Info: https://material-ui.com/customization/typography/#responsive-font-sizes
theme = responsiveFontSizes(theme);

export default theme;

import React from 'react';
import {ifProp, prop, switchProp} from 'styled-tools';
import styled, {css} from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ModalWithTrigger from 'components/ModalWithTrigger';
import {SUBSCRIPTION_STATUSES} from 'constants/communicationPreferences';
import ErrorAlert from 'components/ErrorAlert';
import PropTypes from 'prop-types';

const EmailContainer = styled.div`
  padding: 3.5rem 2.5rem 3.5rem 3.875rem;
  width: 100%;
`;

const EditContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.375rem;

  button {
    font-weight: 700;
  }
`;

const EmailHeader = styled(Typography)`
  margin-bottom: 0.5rem;
  
  ${switchProp('weight', {
  medium: css`
    font-weight: 400;
  `,
  bold: css`
    font-size: 1.125rem;
    font-weight: 700;
  `
})}
`;

const GridRow = styled(Grid)`
  .MuiGrid-item {
    display: flex;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    &:last-of-type {
      ${props => props.theme.breakpoints.up('lg')} {
        padding-left: 7.375rem;
      }
    }

    &:first-of-type,
    &:only-of-type {
      ${props => props.theme.breakpoints.up('lg')} {
        padding-left: 1.5rem;
        padding-right: 7.375rem;
      }
    }
  }
`;

const GridContainer = styled.div`
  ${GridRow} :not(:last-child) {
    margin-bottom: 4rem;
  }
`

const GridHeader = styled(Typography)`
  font-family: ${prop('theme.typography.fontFamilySecondary')};
  font-weight: 700;
  margin-bottom: 1.5rem;
  width: 100%;
`;

const IconContainer = styled.div`
  .MuiSvgIcon-root {
    fill: ${prop('theme.colors.ttRed')};
    height: 2.25rem;
    width: 2.25rem;
  }
`;

const CopyContainer = styled.div`
  padding-left: 3.25rem;

  ${ifProp('icon', css`
    padding-left: 1rem;
  `)}
`;

const GridItem = styled(Grid)`
  &&& {
    padding-left: 0rem;
  }
`

PreferencesContent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  preferenceGroups: PropTypes.array,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  modalContent: PropTypes.element,
  showGroupHeader: PropTypes.bool
}

function PreferencesContent({
  loading,
  error,
  preferenceGroups,
  open,
  handleClose,
  handleOpen,
  modalContent,
  showGroupHeader
}) {
  return (
    <EmailContainer>
      {loading && (
        <Typography>Loading your preferences...</Typography>
      )}
      {!loading && error && (
        <ErrorAlert>Failed to load your email preferences. Please refresh to try again.</ErrorAlert>
      )}
      <>
        <EditContainer>
          <ModalWithTrigger
            ariaLabel='Update email preferences'
            ariaDescribe='Form where a user can update their email subscription preferences'
            icon={<ChevronRightIcon/>}
            padding='narrow'
            triggerText='Edit Preferences'
            triggerType='link'
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          >
            {modalContent}
          </ModalWithTrigger>
        </EditContainer>
        <GridContainer>
          {preferenceGroups.map((pref, index) => {
            const options = pref.emailSubscriptions;

            return (
              <GridRow container key={`email-row-${index}`} spacing={6}>
                {showGroupHeader &&
                  <GridHeader variant='h4'>{pref.groupHeader}</GridHeader>
                }
                {options && options.map((option, index) => (
                  <GridItem item key={`email-type-${option.id || index}`} xs={12} sm={6}>
                    {option.status === SUBSCRIPTION_STATUSES.subscribed && (
                      <IconContainer>
                        <CheckBoxIcon/>
                      </IconContainer>
                    )}
                    <CopyContainer icon={option.status === SUBSCRIPTION_STATUSES.subscribed}>
                      <EmailHeader weight='bold'>{option.subscriptionName}</EmailHeader>
                      {option.subheader && (
                        <EmailHeader weight='medium'>
                          {option.subheader}
                        </EmailHeader>
                      )}
                      {option.description && (
                        <Typography>
                          {option.description}
                        </Typography>
                      )}
                    </CopyContainer>
                  </GridItem>
                ))}
              </GridRow>
            );
          })}
        </GridContainer>
      </>
    </EmailContainer>
  );
}

export default React.memo(PreferencesContent);

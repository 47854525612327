import React, { useState } from 'react';
import get from 'lodash.get';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ModalWithTrigger from 'components/ModalWithTrigger';
import Accordion from 'components/Accordion';
import CancelAccount from './CancelAccount';
import EmailPreferences from './EmailPreferences';
import { useAuth } from 'context/AuthContext';
import YourInfo from './YourInfo';
import { dataLayerPush } from 'utils/dataLayer';
import LuckboxSubscription from './Luckbox';
import {useQuery} from "@apollo/client";
import { Redirect } from 'react-router-dom';
import getEmailPreferencesQuery from "./getEmailPreferences.graphql";

const AccountContainer = styled(Container)`
  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1920px;
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
`;

const AccountHeader = styled(Typography)`
  margin-bottom: 4.125rem;
  margin-top: 2.125rem;
`;

const AccordionContainer = styled.div`
  .MuiAccordion-root {
    border: 0.0625rem solid ${prop('theme.colors.borderGray')};
    margin-bottom: 4.25rem;
    min-height: 5rem;

    h4 {
      font-size: 1.25rem;
    }

    &.Mui-expanded {
      margin-bottom: 4.25rem;

      &:last-child {
        margin-bottom: 4.25rem;
      }
    }
  }

  .MuiAccordionSummary-root {
    height: 5rem;

    &.Mui-expanded {
      border-bottom: 0.0625rem solid ${prop('theme.colors.borderGray')};
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const CancelContainer = styled.div`
  margin-bottom: 3.75rem;

  button {
    font-family: ${prop('theme.typography.fontFamilySecondary')};
    font-size: 0.8125rem;
  }
`;

function Account() {
  const { user } = useAuth();
  const auth0Id = get(user, 'sub', '');
  const [open, setOpen] = useState(false);
  const { data, loading, error } = useQuery(getEmailPreferencesQuery, {
    skip: typeof window === 'undefined',
  });
  const emailPreferenceGroups = data?.communicationPreferences?.emailPreferences?.preferenceGroups ?? [];

  const handleOpen = () => {
    dataLayerPush(`Click to open 'Cancel Your tastylive Account'`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!user) {
    return <Redirect to='/' />
  }

  const baseEmailPreferences = emailPreferenceGroups.filter(grp => grp.groupHeader !== 'Learn Center');
  const learningCenterPreferences = emailPreferenceGroups.filter(grp => grp.groupHeader === 'Learn Center');

  return (
    <Box py={4}>
      <AccountContainer>
        <AccountHeader variant='h1'>Manage Your Profile</AccountHeader>
        <AccordionContainer>
          <Accordion
            icon='chevronDown'
            id='panel1'
            title='Your Info'
            variant='h4'
          >
            <YourInfo auth0Id={auth0Id} />
          </Accordion>
          <Accordion
            icon='chevronDown'
            id='panel2'
            title='Email Preferences'
            variant='h4'
          >
            <EmailPreferences
              preferences={baseEmailPreferences}
              loading={loading}
              error={error}
            />
          </Accordion>
          <Accordion
            icon='chevronDown'
            id='panel3'
            title='Learn Center Preferences'
            variant='h4'
          >
            <EmailPreferences
              preferences={learningCenterPreferences}
              loading={loading}
              error={error}
              showGroupHeader={false}
              title={'Update Learning Center Preferences'}
            />
          </Accordion>
          {/*
            <Accordion
              icon='chevronDown'
              id='panel3'
              title='Learn Center'
              variant='h4'
            >
              <LearnCenter />
            </Accordion>
          */}
          <LuckboxSubscription />
        </AccordionContainer>
        <CancelContainer>
          <ModalWithTrigger
            ariaLabel='Cancel tastylive account'
            ariaDescribe='Interaction to cancel your tastylive account'
            handleClose={handleClose}
            handleOpen={handleOpen}
            height={658}
            open={open}
            padding='wide'
            triggerText='Cancel Your Tastylive Account'
            triggerType='link'
            width={924}
          >
            <CancelAccount onClose={handleClose} />
          </ModalWithTrigger>
        </CancelContainer>
      </AccountContainer>
    </Box>
  );
}

export default React.memo(Account);

import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { useSiteSettings } from 'context/SiteSettingsContext';
import { useVideoPlayerContext } from '../../context/VideoPlayerContext';
import livestreamShape from 'shapes/livestreamShape';
import Skeleton from '@material-ui/lab/Skeleton';
import TastytradeAnalytics from 'videojs/plugins/tastytrade-analytics';
import { usePictureInPictureContext } from 'context/PictureInPictureContext';
import { videoAnalyticsEventHandler } from 'utils/dataLayer';
import { useHistory, useLocation } from 'react-router-dom';
import config from 'config';
import { useMutation } from '@apollo/client';
import updateUserEpisodeDetailsMutation from '../../graphql/mutations/updateUserEpisodeDetails.graphql';
import { useAuth } from 'context/AuthContext';
import { autoOptimizeImage } from 'utils/functions';

const SkeletonContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
`;

const PlayerSkeleton = styled(Skeleton).attrs({ variant: 'rect' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

VideoPlayer.propTypes = {
  videoId: PropTypes.string,
  poster: PropTypes.string,
  autoplay: PropTypes.oneOf([false, true, 'muted', 'play', 'any']),
  onSuccess: PropTypes.func,
  live: PropTypes.bool,
  playerSettings: livestreamShape,
  onEnded: PropTypes.func,
  currentTime: PropTypes.number,
  pictureInPicture: PropTypes.bool,
  mountId: PropTypes.string,
  playbackPosition: PropTypes.number,
  episodeId: PropTypes.string
};

function VideoPlayer({
  videoId,
  poster,
  autoplay = false,
  onSuccess,
  live = false,
  playerSettings = null,
  onEnded,
  currentTime,
  pictureInPicture = false,
  mountId,
  episodeId,
  playbackPosition,
  ...props
}) {
  const { siteSettings } = useSiteSettings();
  const playerRef = useRef(null);
  const onEndedRef = useRef(null);
  const enterPipRef = useRef(null);
  const leavePipRef = useRef(null);
  const historyUnlistenRef = useRef(null);
  const bitrateRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const { removeVideo, setPipId, setReturnPipId, setFloatPipSource, state: { pipReturnUrl, floatPipSource, pipVideoId } } = usePictureInPictureContext();
  const location = useLocation();
  const history = useHistory();
  const [updateUserEpisodeDetails] = useMutation(updateUserEpisodeDetailsMutation);
  const { user } = useAuth();

  const { state: videoPlayerContext, setVolume, setSpeed, setMuted, setBitrate, setVideoDuration, setPlaysinline } = useVideoPlayerContext();
  const publishDate = props.publishDate;
  const cutoffDate = dayjs('2023-02-17').endOf('day').toISOString();
  const showDisclaimer = dayjs(publishDate).isSameOrBefore(cutoffDate);

  useEffect(() => {
    let interval;

    if (history.location.pathname !== '/' && episodeId && user) { // do not store user episode position on home page - live player
      interval = setInterval(() => {
        handleUpdateUserEpisodeDetails();
      }, 10000) // 10 seconds
    }

    return () => clearInterval(interval);
  }, [])

  async function handleUpdateUserEpisodeDetails() {
    if (playerRef.current) {
      const playbackPosition = playerRef.current.cache_.currentTime;
      const episodeDuration = playerRef.current.cache_.duration;

      // Confirm we have the episodeDuration available before trying to save (race condition).
      if (playbackPosition > 0 && episodeDuration) {
        await updateUserEpisodeDetails({
          variables: {
            input: { videoId: episodeId, playbackPosition, episodeDuration }
          }
        });
      }
    }
  }

  // Make sure video does not mount on server-side.
  useEffect(() => {
    // Use setTimeout here so that the state update is called after the full render cycle.
    // This is to address further issues with SSR where dupe audio would occur from the video.
    setTimeout(() => {
      setIsMounted(true);
    }, 0);
  }, []);

  // Set up history route change listener for PIP, so that we can float the video player
  // DOM before the video mount element is removed from route change.
  useEffect(() => {
    if (playerRef.current) {
      // Remove existing history listener if we have one.
      if (historyUnlistenRef.current) {
        historyUnlistenRef.current();
        historyUnlistenRef.current = null;
      }

      history.listen((location, action) => {
        if (!mountId) {
          mountId = videoId || (live ? 'live-player' : 'default-player');
        }

        // If this video is in PIP mode, and we're not floating already, float this video.
        if (pipVideoId === mountId && !floatPipSource) {
          setFloatPipSource(true);
        }
      });
    }

    return () => {
      if (historyUnlistenRef.current) {
        historyUnlistenRef.current();
        historyUnlistenRef.current = null;
      }
    }
  }, [setFloatPipSource, floatPipSource, playerRef.current, videoId, live, pipVideoId]);

  // Use effect for watching prop changes of videojs event handler callbacks.
  // If there are more event handlers needed in the future, this logic should be
  // abstracted to a custom hook.
  useEffect(() => {
    // If videojs player ref is not loaded yet, do nothing.
    if (!playerRef.current) {
      return;
    }

    if (onEnded) {
      // If we already have an event listener bound from prior prop value, remove it.
      if (onEndedRef.current) {
        playerRef.current.off('ended', onEndedRef.current);
      }

      playerRef.current.on('ended', onEnded);
      onEndedRef.current = onEnded;
    } else if (onEndedRef.current) {
      playerRef.current.off('ended', onEndedRef.current);
      onEndedRef.current = null;
    }

    return () => {
      if (playerRef.current && onEndedRef.current) {
        playerRef.current.off('ended', onEndedRef.current);
        onEndedRef.current = null;
      }
    };
  }, [onEnded, playerRef.current]);

  // PIP video events
  useEffect(() => {
    if (!playerRef.current) {
      return;
    }

    if (enterPipRef.current) {
      playerRef.current.off('enterpictureinpicture', enterPipRef.current);
    }

    if (leavePipRef.current) {
      playerRef.current.off('leavepictureinpicture', leavePipRef.current);
    }

    // Handle PIP portal
    if (!mountId) {
      mountId = videoId || (live ? 'live-player' : 'default-player');
    }

    enterPipRef.current = () => {
      setPipId(mountId, location.pathname);
    };

    leavePipRef.current = () => {
      if (location.pathname !== pipReturnUrl) {
        setReturnPipId(mountId);
        history.push(pipReturnUrl);
      } else {
        // On the same page, so just closing the PIP video.
        setPipId(null, null);
      }
    };

    playerRef.current.on('enterpictureinpicture', enterPipRef.current);
    playerRef.current.on('leavepictureinpicture', leavePipRef.current);

    // Remove listeners on unmount.
    return () => {
      if (playerRef.current) {
        if (enterPipRef.current) {
          playerRef.current.off('enterpictureinpicture', enterPipRef.current);
        }

        if (leavePipRef.current) {
          playerRef.current.off('leavepictureinpicture', leavePipRef.current);
        }
      }
    }
  }, [playerRef.current, mountId, videoId, location.pathname, pipReturnUrl]);

  // "live" prop can flag between loading the live player settings vs vod player settings.
  const settingsKey = live ? 'livePlayerSettings' : 'vodPlayerSettings';

  if (!isMounted) {
    return null;
  }

  if (!playerSettings && (!siteSettings || !siteSettings[settingsKey])) {
    return (
      <SkeletonContainer>
        <PlayerSkeleton />
      </SkeletonContainer>
    );
  }

  const settings = playerSettings || siteSettings[settingsKey];
  if (!settings) {
    throw new Error('No player settings available in VideoPlayer component');
  }

  const {
    brightcoveAccount,
    brightcoveAdConfigId,
    brightcoveApplicationId,
    brightcoveEmbed,
    brightcovePlayerId,
    brightcoveVideoId,
  } = settings;

  if (!brightcoveAccount) {
    throw new Error('Brightcove Account ID must be provided in the VideoPlayer playerSettings prop');
  }

  // Success event of the video loading.
  // Bind event listeners here or run any onload functions on the player reference here.
  const handleSuccessEvent = (success) => {
    playerRef.current = success.ref;

    if (playerRef.current) {
      // If we have currentTime, set the current time of the player.
      if (currentTime) {
        playerRef.current.currentTime(currentTime);
      } else if (playbackPosition) {
        playerRef.current.currentTime(playbackPosition);
      }

      const myPlayer = playerRef.current;

      // set video settings to match user settings
      myPlayer.volume(videoPlayerContext.volume);
      myPlayer.muted(videoPlayerContext.muted);
      myPlayer.defaultPlaybackRate(videoPlayerContext.speed);

      myPlayer.qualityLevels().on('change', function ( evt ) {
        if (bitrateRef.current !== null) {
          //update user settings
          setBitrate(this.levels_[evt.selectedIndex].height.toString());
        } else {
          bitrateRef.current = true;

          const items = myPlayer.controlBar.getChild('QualityMenuButton').items;
          for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.options_.label.indexOf(videoPlayerContext.bitrate) !== -1) {
              item.handleClick();
              break;
            }
          }
        }
      });

      // listen to changes in volume
      myPlayer.on('volumechange', function( evt ){
        // set volume setting
        setVolume(evt.target.player.cache_.volume);

        // set mute setting
        const muted = myPlayer.muted();
        setMuted(muted);
      })

      // listen to changes in playback speed
      myPlayer.on('ratechange', function(){
        const playbackRate = myPlayer.playbackRate();
        setSpeed(playbackRate.toString());
      });

      myPlayer.on('loadedmetadata', function(event) {
        const durationValue = myPlayer.duration();
        setVideoDuration(durationValue);
      });

      myPlayer.on('loadstart', function() {
        if (showDisclaimer) {
          setPlaysinline(true);
        } else {
          setPlaysinline(false);
        }
      })

      // Register custom plugin(s)
      if (!playerRef.current.hasPlugin('tastytradeAnalytics')) {
        window.videojs.registerPlugin('tastytradeAnalytics', TastytradeAnalytics);
      }

      // Load custom plugin(s)
      if (playerRef.current.hasPlugin('tastytradeAnalytics')) {
        playerRef.current.tastytradeAnalytics({
          eventHandler: videoAnalyticsEventHandler,
          gaTrackerId: config.analytics.gaTrackerId,
        });
      } else {
        console.error('Expected plugin "tastytradeAnalytics" to exist on player, but no plugin found.');
      }

      // Setup callbacks.
      if (onEnded) {
        playerRef.current.on('ended', onEnded);
        onEndedRef.current = onEnded;
      }
    }

    onSuccess && onSuccess(success);
  }

  return (
    <ReactPlayerLoader
      accountId={brightcoveAccount}
      videoId={videoId || brightcoveVideoId}
      adConfigId={brightcoveAdConfigId}
      applicationId={brightcoveApplicationId}
      embedId={brightcoveEmbed}
      playerId={brightcovePlayerId}
      poster={poster}
      onSuccess={handleSuccessEvent}
      options={{
        aspectRatio: '16:9',
        autoplay: autoplay,
        playsinline: videoPlayerContext.playsinline,
        html5: {
          useBandwidthFromLocalStorage: true,
        },
      }}
      {...props}
    />
  );
}

export default React.memo(VideoPlayer);

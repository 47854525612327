import React from 'react';
import PropTypes from 'prop-types';
import linkShape from 'shapes/linkShape';
import { isAbsoluteUrl } from 'utils/linkUtils';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from 'components/Button'
import linkResolver from 'utils/linkResolver';

PageLink.propTypes = {
  link: linkShape,
  button: PropTypes.bool,
  component: PropTypes.elementType,
  children: PropTypes.node,
  componentProps: PropTypes.object,
  absoluteComponent: PropTypes.elementType,
};

function PageLink({
  link,
  button,
  component: CustomComponent,
  children,
  absoluteComponent = undefined,
  componentProps = {},
  ...props
}) {
  const MainComponent = CustomComponent || (button ? Button : Link);

  if (!link) {
    // If there's no link, let's just render the component out.
    return <MainComponent {...props}>{children}</MainComponent>;
  }

  const url = linkResolver(link);
  const isAbsolute = isAbsoluteUrl(url);
  const { openInNewWindow } = link;

  // If link is absolute, we can't render it with react-router Link component,
  // and we need to use href instead of to.
  // If the link should open in a new window, we can't render it with react-router either,
  // because it does not support setting the target prop.
  const renderComponent = isAbsolute ? absoluteComponent : RouterLink;
  const linkComponentProps = isAbsolute || openInNewWindow
    ? { href: url, target: openInNewWindow && '_blank', rel: openInNewWindow && isAbsolute && 'noopener' }
    : { to: url };

  return (
    <MainComponent component={renderComponent} {...linkComponentProps} {...props} {...componentProps}>
      {children}
    </MainComponent>
  );
}

export default React.memo(PageLink);

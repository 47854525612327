import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, theme, prop } from 'styled-tools';
import ctaButtonShape from 'shapes/ctaButtonShape'; 
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import buttonTypes from 'constants/buttonTypes';
import PageLink from 'components/PageLink';
import BaseIcon from 'components/Icon';

const Icon = styled(BaseIcon)`
  margin-left: 0.5rem;

  ${ifProp('iconColor', css`
    &&& {
      fill: ${prop('iconColor')};
    }
  `)}

  ${ifProp('alertIcon', css`
    &&& {
      width: 1rem;
      height: 1rem;
      fill: ${theme('colors.white')};
    }
  `)}

  ${ifProp({iconColor: 'default'}, css`
    fill: ${theme('colors.white')};
  `)}
`;

const StartIcon = styled(BaseIcon)`
  margin-right: 0.5rem;

  ${ifProp({iconColor: 'default'}, css`
    fill: ${theme('colors.white')};
  `)}
`;

const PageLinkContainer = styled(PageLink) `
  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
  }

  &:first-child {
    ${ifProp({$type: 'link'}, css`
      padding-left: 0;
    `)}
  }

  &:hover{
    ${ifProp({$icon: 'arrowRight'}, css`
      svg {
        transition: transform 0.25s ease, width 0.25s ease;
        transform: scaleX(1.3);
        width: 1.3rem;
      }
    `)}
  }
  
  ${ifProp('$justify', css`
    justify-content: ${prop('$justify')};
  `)}
`;

const TextContainer = styled.div`
  font-weight: 600;
  font-size: 1rem;

  ${ifProp('$color', css`
    color: ${prop('$color')};
  `)}
`;

const buttonTypePropsMapping = {
  [buttonTypes.default]: {
    variant: 'contained',
    color: 'default',
  },
  [buttonTypes.primary]: {
    variant: 'contained',
    color: 'primary',
  },
  [buttonTypes.secondary]: {
    variant: 'contained',
    color: 'secondary',
  },
  [buttonTypes.link]: {
    variant: 'text',
  },
  [buttonTypes.defaultOutlined]: {
    variant: 'outlined',
    color: 'default',
  },
  [buttonTypes.primaryOutlined]: {
    variant: 'outlined',
    color: 'primary',
  },
  [buttonTypes.secondaryOutlined]: {
    variant: 'outlined',
    color: 'secondary',
  },
}

CtaButton.propTypes = {
  data: ctaButtonShape,
  icon: PropTypes.element,
  iconColor: PropTypes.string,
  startIcon: PropTypes.element,
  color: PropTypes.string,
  alertIcon: PropTypes.string,
  overrideStyle: PropTypes.string,
  overrideJustify: PropTypes.string,
  eventbriteId: PropTypes.string,
};

const EVENTMODAL = 'eventbright signup';

function CtaButton({ data, icon, iconColor, startIcon, color, alertIcon, overrideStyle, overrideJustify, eventbriteId }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { buttonLink = '', buttonType, buttonCtaName, buttonCtaMobileName, buttonAction } = data;
  let buttonProps = buttonType && buttonTypePropsMapping[buttonType.toLowerCase()];
  const isModal = buttonAction === EVENTMODAL;

  const useStyles = makeStyles((theme) => ({
    redOutlined: {
      border: `0.155rem solid ${theme.colors.ttRed}`,
      color: theme.colors.ttRed, 
      padding: '0.5rem 1.25rem',
      borderRadius: '0',
      '&:hover': {
        backgroundColor: theme.colors.ttRed,
        color: theme.colors.white,
      },
    },
    redSquareFilled: {
      backgroundColor: theme.colors.ttRed,
      color: theme.colors.white,
      padding: '0.5rem 1.25rem !important',
      borderRadius: '0',
      '&:hover': {
        backgroundColor: theme.colors.ttMuted,
        color: theme.colors.white,
      },
      marginBottom: '-1rem'
    },
  }));

  const classes = useStyles();
  const appliedClass = classes[overrideStyle || buttonType];

  return (
    <PageLinkContainer
      $icon={icon}
      link={buttonLink}
      button
      $type={overrideStyle || buttonType} {...buttonProps}
      className={appliedClass ? appliedClass : null}
      $justify={overrideJustify && overrideJustify}
      id={isModal && eventbriteId}
    >
      {startIcon && <StartIcon icon={startIcon} color={iconColor} />}
      {isDesktop && (
        <TextContainer $color={color}>{buttonCtaName}</TextContainer>
      )}
      {!isDesktop && (
        <TextContainer $color={color}>
          {buttonCtaMobileName ? buttonCtaMobileName : buttonCtaName}
        </TextContainer>
      )}
      {icon && <Icon icon={icon} iconColor={iconColor} alertIcon={alertIcon} />}
    </PageLinkContainer>
  );
}

export default CtaButton;
